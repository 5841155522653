import { FC } from 'react';

import { graphql } from 'gatsby';
import { HeadInner } from '../../components/Head';
import { Layout } from '../../components/layout';
import { ArticleHeading } from '../../components/organisms/ArticleHeading';
import { NewsTemplate } from '../../components/news/NewsTemplate';

type HeadProps = {
  data: GatsbyTypes.SerivceUpdateTemplateQueryQuery;
};

export const Head: FC<HeadProps> = (props) => {
  const serviceUpdate = props.data.contentfulServiceUpdate;
  if (!serviceUpdate) {
    return null;
  }
  const coverFile = serviceUpdate?.cover?.file;
  return (
    <HeadInner
      path={`/updates/${serviceUpdate.slug}`}
      title={serviceUpdate.title}
      description={serviceUpdate?.description?.description}
      imageUrl={coverFile?.url ? `https:${coverFile.url}` : undefined}
      width={coverFile?.details?.image?.width}
      height={coverFile?.details?.image?.height}
    />
  );
};

const ServiceUpdateTemplate: FC<{
  data: GatsbyTypes.SerivceUpdateTemplateQueryQuery;
}> = (props) => {
  const serviceUpdate = props.data.contentfulServiceUpdate;
  if (!serviceUpdate) return null;
  // 日付を切り出し
  const date = `${serviceUpdate.publishedAt?.slice(
    0,
    4
  )}.${serviceUpdate.publishedAt?.slice(
    5,
    7
  )}.${serviceUpdate.publishedAt?.slice(8, 10)}`;

  const links = [
    { name: 'お知らせ', path: '/news/' },
    {
      name: `${serviceUpdate.title}`,
      path: `/updates/${serviceUpdate.slug}`,
    },
  ];

  return (
    <Layout links={links}>
      <ArticleHeading category='news' title={serviceUpdate.title ?? ''} />
      <NewsTemplate
        newsCategory='serviceUpdate'
        publishAt={`${date}`}
        content={serviceUpdate.content?.childMdx?.body!}
      />
    </Layout>
  );
};

export const query = graphql`
  query SerivceUpdateTemplateQuery($id: String) {
    contentfulServiceUpdate(id: { eq: $id }) {
      id
      slug
      title
      publishedAt
      description {
        description
      }
      content {
        content
        childMdx {
          body
        }
      }
      cover {
        gatsbyImageData
        file {
          url
          details {
            image {
              height
              width
            }
          }
        }
      }
      metadata {
        tags {
          name
        }
      }
    }
  }
`;

export default ServiceUpdateTemplate;
